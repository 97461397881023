
























































import {Component, Vue} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import User from '@/models/User';
import { NavigationSubItemInterface } from '@/interfaces/NavigationSubItem.interface';
import UserInitialsConfig from '@/misc/UserInitialsConfig';
import {authStoreGetter, authStoreMutations} from '@/stores/auth.store';
import {tenantStoreGetter, tenantStoreMutations} from '@/stores/tenant.store';
import Tenant from '@/models/Tenant';
import {Permission} from '@/misc/Enums/permission.enum';

const AuthStore = namespace('auth');
const TenantStore = namespace('tenant');

/**
 * Profile component for navigation bar.
 */
@Component({
  components: {
    ImportComponent: () => import(
        '@/components/shared/Import.component.vue'),
    UserInitialsComponent: () => import(
        /* webpackChunkName: "UserInitialsComponent" */
        '@/components/user/UserInitials.component.vue'),
  },
})
export default class NavigationProfileComponent extends Vue {

    private options: NavigationSubItemInterface[] = [];
    private userConfig: UserInitialsConfig = {
      showFullName: false,
      big: true,
      showTooltip: false,
    };

    private showImportModal: boolean = false;

    @AuthStore.Mutation(authStoreMutations.CLEAR_LOGIN)
    private clearLogin!: () => void;
    @TenantStore.Mutation(tenantStoreMutations.RESET)
    private resetTenantStore!: () => void;
    @TenantStore.Getter(tenantStoreGetter.ACTIVE_TENANT)
    public _tenant!: Tenant;

    @AuthStore.Getter(authStoreGetter.USER)
    private _user!: User;

    constructor() {
        super();
    }

    private mounted() {
      this.options = [{
        title: 'NAVIGATION.DROPDOWN.SETTINGS',
        routerName: 'settings',
        permission: this.$userRoleHandler.hasPermission(Permission.USER_READ_OWN),
        icon: '',
      }, {
        title: 'NAVIGATION.DROPDOWN.LOGOUT',
        routerName: 'logout',
        permission: true,
        icon: '',
      }];
    }

    get user(): User {
        return User.parseFromObject(this._user);
    }

    /**
     * On click handler that activates a route
     * @param item
     */
    public onNavItemClick(item: any) {
        if (item.routerName === 'logout') {
            this.handleLogout();
            this.$router.push({name: 'login'});
        } else if (item.routerName === 'settings') {
          this.$router.push({
            name: 'settings',
            params: {
              tenantId: this.$route.params.tenantId,
            },
            query: {tab: 'profile'},
          });
        }
    }

    /**
     * Handles logout and clears all store values
     */
    private handleLogout() {
        this.clearLogin();
        this.resetTenantStore();
    }

  public closeImportModal() {
    this.showImportModal = false;
  }
}
